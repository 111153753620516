<template>
	<span class="legend-item">
		<v-icon :color="color" x-small class="pb-4">fas fa-circle</v-icon>
		<p class="text-subtitle-2 ml-2">{{ text }}</p>
	</span>
</template>

<script>
export default {
	props: ['text', 'color'],

	components: {},

	computed: {}
};
</script>

<style lang="postcss">
@import '../../../styles/app-variables.pcss';
.legend-item {
	height: 20px;
	display: flex;
	align-items: center;
}
</style>
