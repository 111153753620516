<template>
	<v-row>
		<v-col cols="2">
			<pui-date-field
				:id="`dateini-giswebtoolbar`"
				v-model="filter.dateini"
				:label="$t('gisweb.toolbar.dateini')"
				toplabel
				:min="new Date().setDate(new Date().getDate() - 1)"
			></pui-date-field>
		</v-col>
		<v-col cols="2">
			<pui-date-field
				:id="`dateend-giswebtoolbar`"
				v-model="filter.dateend"
				:label="$t('gisweb.toolbar.dateend')"
				toplabel
				:min="filter.dateini || null"
			></pui-date-field>
		</v-col>

		<v-col cols="3">
			<pui-select
				id="shiptype"
				attach="shiptype"
				:label="$t('gisweb.toolbar.shiptype')"
				toplabel
				clearable
				v-model="filter.shiptypes"
				modelName="shiptypensw"
				:itemsToSelect="filter.shiptypes"
				:modelFormMapping="{ code: 'shiptypes' }"
				itemValue="code"
				itemText="description"
				:order="{ description: 'asc' }"
				:fixedFilter="filterByPortAuthorityUser"
				multiple
			></pui-select>
		</v-col>
		<v-col cols="3">
			<pui-select
				id="berth"
				attach="berth"
				:label="$t('gisweb.toolbar.berth')"
				v-model="filter"
				modelName="berth"
				itemValue="id"
				itemText="name"
				multiple
				:itemsToSelect="filter.berth"
				:modelFormMapping="{ id: 'berth' }"
				return-object
				solo
				flat
				hide-details
				toplabel
				clearable
			></pui-select>
		</v-col>
		<v-col cols="2">
			<pui-text-field
				:id="`text-giswebtoolbar`"
				v-model="filter.text"
				:label="$t('gisweb.toolbar.text')"
				toplabel
				maxlength="100"
			></pui-text-field>
		</v-col>
	</v-row>
</template>

<script>
export default {
	mixins: [],
	data() {
		return {
			tabmodel: 0,
			model: {
				dateini: null,
				dateend: null,
				shiptype: null,
				berth: null,
				text: null
			}
		};
	},
	props: {
		filter: {
			type: Object,
			required: true
		}
	},
	computed: {
		filterByPortAuthorityUser() {
			return {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'port_authority_id', op: 'eq', data: this.$store.getters.getPortAuthorityId }]
			};
		}
	}
};
</script>

<style scoped></style>
