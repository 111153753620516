<template>
	<span :style="{ display: inline ? 'flex;' : '' }">
		<b class="cardHeaderColumns font-weight-bold text-subtitle-2">{{ label }}</b> <br />
		<p class="text-subtitle-2" :style="{ 'margin-left': inline ? '10px' : '' }" :class="getClassDay()">{{ value }} {{ suffix }}</p>
	</span>
</template>

<script>
export default {
	props: {
		label: {
			type: String,
			required: true
		},
		value: {
			type: [String, Number],
			default: ''
		},
		inline: {
			type: Boolean,
			default: false
		},
		suffix: {
			type: String,
			required: false
		},
		day: {
			type: String,
			default: ''
		}
	},
	methods: {
		getClassDay() {
			if (this.day == '') {
				return null;
			} else if (this.day == 'today') {
				return 'date_today';
			} else if (this.day == 'yesterday') {
				return 'date_yesterday';
			} else {
				return null;
			}
		}
	}
};
</script>

<style>
@import '../../../../styles/app-variables.pcss';

.v-application .text-subtitle-2 {
	font-family: Overpass, Montserrat, sans-serif !important;
}

.cardHeaderColumns {
	color: #6f7480;
}

.date_today {
	background-color: #7adb98;
	display: inline-block;
	padding: 3px;
	border-radius: 5px !important;
}

.date_yesterday {
	background-color: #69b9ff;
	display: inline-block;
	padding: 3px;
	border-radius: 5px !important;
}
</style>
