<template>
	<div>
		<div class="font-weight-bold title text-subtitle-2">{{ $t('menu.operation') }}</div>
		<table class="giswebTable">
			<tr class="headers">
				<th v-for="(header, i) in headers" :key="i" class="font-weight-bold giswebTh">{{ header.name }}</th>
			</tr>
			<tr v-for="(operation, i) in stop.operations" :key="i" class="giswebTr">
				<td v-for="(header, o) in headers" :key="o" class="giswebTd">{{ operation[header.id] || '-' }}</td>
			</tr>
		</table>
	</div>
</template>

<script>
export default {
	components: {},
	mixins: [],
	data() {
		return {
			headers: [
				{ id: 'operationtype', name: this.$t('gisweb.detail.operationsdata.operationtype') },
				{ id: 'loadtypename', name: this.$t('gisweb.detail.operationsdata.loadtypename') },
				{ id: 'quantity', name: this.$t('gisweb.detail.operationsdata.quantity') },
				{ id: 'stevedorecompanyname', name: this.$t('gisweb.detail.operationsdata.stevedorecompanyname') }
			]
		};
	},
	props: {
		stop: {
			type: Object,
			required: true
		}
	},
	computed: {},
	created() {},
	methods: {}
};
</script>

<style lang="postcss">
@import '../../../../styles/app-variables.pcss';

.title {
	color: var(--primary);
	font-size: 12px;
	font-family: Montserrat;
}
.giswebTable {
	border-collapse: collapse;
	width: 100%;
	background-color: #d7ebff;
	font-family: Montserrat;
}

.giswebTh {
	text-align: left;
	color: #6f7480;
	padding: 8px;
	font-weight: lighter;
}

.giswebTd {
	border: 1px solid #dadde0;
	text-align: left;
	padding: 2px;
	padding-left: 4px;
}

.giswebTr:nth-child(even) {
	background-color: white;
}

.giswebTr:nth-child(odd) {
	background-color: #f7f8fc;
}
</style>
