<template>
	<div class="mx-4">
		<v-tabs v-model="tabmodel" slider-color="primary" @change="updateTabModel">
			<v-tab v-if="$store.getters.hasFunctionality('READ_GISWEB_OPERATIONS')" :key="0" :href="'#operations'">{{
				$t('gisweb.operations.title')
			}}</v-tab>
			<v-tab v-if="$store.getters.hasFunctionality('READ_GISWEB_ENTRY')" :key="1" :href="'#entry'">{{ $t('gisweb.entry.title') }}</v-tab>
			<v-tab v-if="$store.getters.hasFunctionality('READ_GISWEB_EXIT')" :key="2" :href="'#exit'">{{ $t('gisweb.exit.title') }}</v-tab>
			<v-tab v-if="$store.getters.hasFunctionality('READ_GISWEB_REPAIRING')" :key="3" :href="'#repair'">{{ $t('gisweb.repair.title') }}</v-tab>
			<v-tab v-if="$store.getters.hasFunctionality('READ_GISWEB_FORECAST')" :key="4" :href="'#forecast'">{{
				$t('gisweb.forecast.title')
			}}</v-tab>
		</v-tabs>
		<v-divider class="mb-4"></v-divider>
		<v-tabs-items v-model="tabmodel">
			<v-tab-item :key="0" lazy :value="'operations'">OPERATIONS</v-tab-item>
			<v-tab-item :key="1" lazy :value="'entry'"><GiswebTab :filter="filter" :model="models[0]" /></v-tab-item>
			<v-tab-item :key="2" lazy :value="'exit'"> EXIT</v-tab-item>
			<v-tab-item :key="3" lazy :value="'repair'"> REPAIR</v-tab-item>
			<v-tab-item :key="4" lazy :value="'forecast'"><GiswebTab :filter="filter" :model="models[1]" /></v-tab-item>
		</v-tabs-items>
	</div>
</template>

<script>
import GiswebTab from './sections/GiswebTab.vue';

export default {
	components: {
		GiswebTab
	},
	mixins: [],
	data() {
		return {
			tabmodel: 'operations',
			filter: {
				dateini: null,
				dateend: null,
				shiptype: null,
				berth: null,
				text: null
			},
			models: ['stopentry', 'stopforecast']
		};
	},
	mounted() {
		this.tabmodel = this.$store.getters.getTabModel;
	},
	methods: {
		updateTabModel(newTab) {
			this.$store.commit('setTabModel', newTab);
		}
	}
};
</script>

<style scoped></style>
